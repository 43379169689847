import { useContext } from "react";
import SettingCard from "../SettingCard";
import { AuthContext } from "../../../../components/Context/AuthContext";
import { useTranslation } from "react-i18next";

const MerchantInfo = () => {
  const { t } = useTranslation();
  const { mid } = useContext(AuthContext);
  return (
    <SettingCard title={t("components.settings.account.OrganisationID")}>
      <div className="w-1/3 lg:w-1/2 xs:w-full">
        <h1 className="mt-4">{mid}</h1>
      </div>
    </SettingCard>
  );
};

export default MerchantInfo;
