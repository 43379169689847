import { useTranslation } from "react-i18next";
import { Abo } from "../../../../types/Abo";
import { a11yProps } from "../../../../utils/helper";
import { SxProps, Tab, Tabs, Theme } from "@mui/material";
import { useState } from "react";
import TabPanel from "./TabPanel";
import OverviewTab from "./Tabs/Overview";
import TransactionTab from "./Tabs/Transaction";
import ProductTab from "./Tabs/Product";

type DetailviewAboProps = {
  id: string;
  abo: Abo | undefined;
  transactions?: any[];
  payments?: any[];
  onClick?: (path: string, object: any) => void;
};

const AboDetailView = (props: DetailviewAboProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const customTabStyle: SxProps<Theme> = {
    fontFamily: "Comfortaa",
    "& .Mui-selected": {
      color: "black",
      backgroundColor: "green",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "orange",
      height: 3,
    },
    "& .MuiTab-root.Mui-selected": {
      color: "red",
    },
  };

  return (
    <>
      {props.abo !== undefined ? (
        <>
          <div className="xl:p-4">
            <div className="text-left m-8 p-4 rounded rounded-xl bg-white w-1/2 xl:w-full xl:mx-auto">
              <Tabs
                className="mb-8"
                value={value}
                onChange={handleChange}
                sx={{
                  "& button.Mui-selected": {
                    color: "black",
                    fontWeight: "bold",
                  },
                }}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "black",
                    color: "black",
                  },
                }}
              >
                <Tab
                  sx={customTabStyle}
                  label={t("components.abonnement_details.tabs.overview")}
                  {...a11yProps(0)}
                />
                <Tab
                  sx={customTabStyle}
                  label={t("components.abonnement_details.tabs.transactions")}
                  {...a11yProps(1)}
                />
                {/* <Tab
                  sx={customTabStyle}
                  label={t("components.abonnement_details.tabs.payments")}
                  {...a11yProps(2)}
                /> */}
                <Tab
                  sx={customTabStyle}
                  label={t("components.abonnement_details.tabs.products")}
                  {...a11yProps(3)}
                />
              </Tabs>
              <TabPanel index={0} value={value}>
                <OverviewTab id={props.id} abo={props.abo} />
              </TabPanel>
              <TabPanel index={1} value={value}>
                <TransactionTab
                  onClick={props.onClick}
                  transactions={props.transactions}
                />
              </TabPanel>
              <TabPanel index={2} value={value}>
                <ProductTab products={props.abo.products} />
              </TabPanel>
            </div>
          </div>
        </>
      ) : (
        <div className="w-max m-auto p-4 mt-8">
          {t("components.abonnement_details.not_found", { id: props.id })}
        </div>
      )}
    </>
  );
};

export default AboDetailView;
