import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Banner from "../../../components/Banner";
import SettingsWrapper from "../../../components/SettingsWrapper";
import LanguageSettings from "./LanguageSettings";
import ProfileSettings from "./ProfileSettings";
import DeleteAccountDialog from "./DeleteAccountDialog";

interface IAccountState {
  stored: boolean;
}

const Account = () => {
  const { t } = useTranslation();
  const profileSettingRef = useRef<any>(null);
  const languageSettingRef = useRef<any>(null);
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false);

  const [accountState, setAccountState] = useState<IAccountState>({
    stored: false,
  });

  const saveAccountData = () => {
    setAccountState({
      ...accountState,
      stored: false,
    });
    profileSettingRef.current.storeProfileData();
    languageSettingRef.current.saveLanguage();
    setAccountState({
      ...accountState,
      stored: true,
    });
  };

  return (
    <>
      {accountState.stored ? (
        <Banner
          cancelable={true}
          type={2}
          text={t("components.settings.mydata.stored")}
        />
      ) : (
        <></>
      )}
      <SettingsWrapper title={t("components.settings.account.account")}>
        <ProfileSettings ref={profileSettingRef} />
        <LanguageSettings ref={languageSettingRef} />
        {deleteDialog === true && (
          <DeleteAccountDialog setDialogVisible={setDeleteDialog} />
        )}
        <input
          onClick={() => saveAccountData()}
          type="submit"
          id="save"
          value={"" + t("components.settings.account.save")}
          className="block w-max rounded-xl p-2 w-1/4 xs:w-full text-base my-8 box-border text-white bg-black cursor-pointer"
        />
        <input
          onClick={() => setDeleteDialog(true)}
          type="submit"
          id="save"
          value={"" + t("components.settings.account.delete")}
          className="block w-max rounded-xl p-2 w-1/4 xs:w-full text-base my-8 box-border text-white bg-red-800 cursor-pointer"
        />
      </SettingsWrapper>
    </>
  );
};

export default Account;
